import { render, staticRenderFns } from "./Personal.vue?vue&type=template&id=f777c2ec&scoped=true&"
import script from "./Personal.vue?vue&type=script&lang=ts&"
export * from "./Personal.vue?vue&type=script&lang=ts&"
import style0 from "./Personal.vue?vue&type=style&index=0&id=f777c2ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f777c2ec",
  null
  
)

export default component.exports